import '../styles/global.scss';
import '../styles/pages/dataroom.scss';

import {HeadFC, navigate} from "gatsby";

import React, {useEffect, useState, useRef} from 'react';


import {
    BigLogoImage,
    LoadingGif,
} from "../images";
import HeaderDataRoom from '../components/HeaderDataRoom';
import DataRoomDash from '../components/DataRoomDash';
import {toast, ToastContainer} from "react-toastify";
import axios from 'axios';


type Props = {
    id: string,
}

const DataroomPage = ({id}: Props) => {

    const [isLoading, setLoading] = useState(true);
    const [drIsVisible, setDrIsVisible] = useState('');
    const [formLoading, setFormLoading] = useState(false);
    const [formStatus, setFormStatus] = useState(true);
    const [email, setEmail] = useState('');
    const [visibilityStatus, setVisibilityStatus] = useState('');
    const inputRef = useRef();

    const onPageLoad = () => {
        setLoading(false);
    }

    const storeLocalStorageVal = () => {
        if(localStorage.getItem('dataroomIsVisible')){
            setVisibilityStatus(localStorage.getItem('dataroomIsVisible'));
            setDrIsVisible(localStorage.getItem('dataroomIsVisible'));
        }
    }
    // const showForm = (status:boolean) => {
    //     setFormStatus(status);
    //     status ? inputRef.current.focus() : null;
    // }

    const onFormSubmit =async () => {
        if (email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )) {
            setFormLoading(true);
            axios.post('https://podolyan.com.ua/aeer-dataroom-email-send.php', { email }).then(function (response) {
                localStorage.setItem('dataroomIsVisible', 'visible');
                setDrIsVisible('visible');
                setFormLoading(false);
                setTimeout(() => {
                    setVisibilityStatus('visible');
                }, 500);

                toast('Signed up successfully', {
                    autoClose: 3000,
                    type: 'success',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            })
            .catch(function (error) {
                setFormLoading(false);
                setDrIsVisible('');
                toast('Invalid e-mail address', {
                    autoClose: 3000,
                    type: 'error',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            });
        } else {
            setFormLoading(false);
            setDrIsVisible('');
            toast('Invalid e-mail address', {
                autoClose: 3000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }

    useEffect(() => {
        storeLocalStorageVal();
        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);


    return (
        <>
            <style>
                {
                    `body {
                        background: #101010;
                        min-height: initial
                    }`
                }
            </style>
            {
                isLoading && (
                    <div className="loadWrap">
                        <div className="loadWrapLogo">
                            <img width={180} src={BigLogoImage} alt=""/>
                            <div className="loadWrapText">
                                <img width={16} src={LoadingGif} alt=""/>
                                Loading..
                            </div>
                        </div>
                    </div>
                )
            }

            <div className="dataRoom black">
                <HeaderDataRoom activeTab={1} />

                {
                    (drIsVisible) ? (
                        <DataRoomDash status='visible' />
                    ) : (
                        <DataRoomDash status='hidden' />
                    )
                }

                {formLoading && (
                    <div className="loaderWrap">
                        <div className="loader">
                            <img width={44} src={LoadingGif} alt=""/>
                        </div>
                    </div>
                )}

                {!visibilityStatus && (
                    <div className={`blurFormWrap flex ${formStatus ? 'active' : ''}`}>
                        <div className="blurFormOverlay"></div>
                        <div className="blurForm">
                            <div className="blurFormTitle">Enter your email <br /> to access the Data Room</div>
                            <div className="blurForm">
                                <div className={`solutionsEmailInput`}>
                                    {/* <p className="solutionsEmailTitle">Enter your email to continue</p> */}
                                    <label htmlFor="email">Email</label>
                                    <input id="email"
                                        onChange={(e) => setEmail(e.target.value)}
                                        type="email"
                                        placeholder="Please enter your email address"
                                        autoComplete="off"
                                        ref={inputRef} />
                                    <button onClick={() => onFormSubmit()} className="solutionsBtn gradient">Sign Up</button>
                                </div>
                                <p className="solutionsSecure static">Your information is 100% secure. AEER Platform does not share your personal information with third parties</p>
                            </div>
                        </div>
                    </div>
                )}    

                <ToastContainer/>
            </div>
            
        </>
    )
}

export default DataroomPage;

export const Head: HeadFC = () => (
    <>
        <title>Solutions - AEER PLATFORM</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
    </>
);
